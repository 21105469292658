import * as Ariakit from "@ariakit/react";
import { forwardRef } from "react";
import { css, cx } from "~/design/generated/css";
import { button } from "~/design/generated/recipes";
import type { ButtonVariantProps } from "~/design/generated/recipes";
import { ButtonSlot, type ButtonSlotProps } from "./ButtonSlot";
import type { IconProps } from "./Icon";

export type ButtonProps = ButtonVariantProps &
  Ariakit.ButtonProps & {
    loading?: boolean;
    iconOnly?: boolean;
    startSlot?: ButtonSlotProps["slot"];
    endSlot?: ButtonSlotProps["slot"];
    startSlotAlign?: "center" | "side";
    endSlotAlign?: "center" | "side";
    startSlotColor?: IconProps["color"];
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    color,
    variant,
    size,
    roundness,
    align,
    children,
    full,
    className,
    startSlot,
    endSlot,
    loading,
    iconOnly,
    backdropBlur,
    startSlotAlign = "center",
    endSlotAlign = "center",
    startSlotColor,
    focusRingDisabled,
    ...restProps
  } = props;

  const buttonClasses = button({
    color,
    variant,
    size,
    roundness,
    align,
    full,
    startSlotAlign,
    endSlotAlign,
    iconOnly,
    backdropBlur,
    hasStartSlot: Boolean(startSlot),
    hasEndSlot: loading || Boolean(endSlot),
    focusRingDisabled,
  });

  return (
    <Ariakit.Button {...restProps} className={cx(buttonClasses.root, className)} ref={ref}>
      <>
        <div className={buttonClasses.startSlot}>
          <ButtonSlot slot={startSlot} size={size} color={startSlotColor} />
        </div>
        {children}
        <div className={buttonClasses.endSlot}>
          {loading ? (
            <div className={cx(css({ animation: "spin 1s linear infinite" }))}>
              <ButtonSlot slot="loader" size={size} />
            </div>
          ) : (
            <ButtonSlot slot={endSlot} size={size} />
          )}
        </div>
      </>
    </Ariakit.Button>
  );
});
